import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import EntityShareAccess from 'components/Globals/EntityShareAccess';
import ShareEntity from 'components/Globals/ShareEntity';
import OrgHeaderActionsList from 'components/Organization/Display/OrgHeaderActionsList';
import KebabMenu from 'components/uiLibrary/KebabMenu';
import { SecondaryButton } from 'components/uiLibrary/LinkButton';
import Modal from 'components/uiLibrary/Modal';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import CastingToolPromo from 'components/Globals/CastingToolPromo';
import FAQ from 'components/Globals/FAQ';
import EntityEditButton, { useEntityEditPermissions } from 'components/Globals/EntityEditButton';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useTranslation } from 'src/i18n';
import { COMPONENTS } from 'components/Globals/Analytics/constants';

import { ENTITY_MAIN_TABS, ENTITY_TYPE, TP, ENTITY_DETAILS_TAB } from 'constants/index';

import classes from './Actions.module.scss';
import { useStickyContext } from '../../StickyContainer';

const Actions = ({ entityType, entity, trackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const isSticky = useStickyContext();
  const { permissions, navigate, mainPath } = usePageContext();
  const { organizationPermissions } = permissions;
  const { isMobile, isDesktop } = useDeviceTypeLayouts();
  const { showEditBtn, canEdit, onEditClickHandler } = useEntityEditPermissions();
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseModalDrawer = () => {
    setIsOpen(false);
  };

  const { primaryActions, collapsedActions } = useMemo(() => {
    // TODO: Connect to data
    const hasTickets = entity?.stats?.tickets?.exists;
    const hasWatchOptions = entity?.stats?.media?.videos?.digital?.exists;
    const actions = {
      primary: [],
      collapsed: [],
    };

    if (hasTickets && mainPath !== ENTITY_MAIN_TABS.PERFORMANCES) {
      const ticketCTA = {
        label: t(`${TP}.FN_TICKETS`),
        leftIcon: <SpriteIcon icon="tickets" size={16} />,
        isLink: true,
        ...navigate.getLinkProps({ entityType, entity, path: ENTITY_MAIN_TABS.PERFORMANCES, onlyLinkProps: true }),
        size: 'medium',
        trackingData: {
          ...trackingData,
          component: COMPONENTS.TICKET_CTA,
        },
      };
      actions.primary.push(ticketCTA);
      actions.collapsed.push(ticketCTA);
    }

    if (hasWatchOptions) {
      const watchOptionsCTA = {
        label: t(`${TP}.m_WATCH`),
        leftIcon: <SpriteIcon icon="play_circle_outline" size={16} />,
        size: 'medium',
        isLink: true,
        ...navigate.getLinkProps({
          entityType,
          entity,
          path: `${ENTITY_MAIN_TABS.VIDEOS}/${ENTITY_DETAILS_TAB.DIGITAL}`,
          ...(entityType === ENTITY_TYPE.ARTIST && { pro: false }),
        }),
        trackingData: {
          ...trackingData,
          component: COMPONENTS.WATCH_OPTIONS_CTA,
        },
      };
      actions.primary.push(watchOptionsCTA);
      actions.collapsed.push(watchOptionsCTA);
    }

    if (organizationPermissions.canEdit) {
      actions.collapsed.push(
        <ShareEntity
          entityType={entityType}
          entity={entity}
          className={classes.shareEntityListItem}
          trackingData={{
            ...trackingData,
            component: COMPONENTS.SHARE_CTA,
          }}
        />,
        <EntityShareAccess
          entityType={entityType}
          entity={entity}
          isMoreAction
          trackingData={{
            ...trackingData,
            component: COMPONENTS.SHARE_ACCESS_CTA,
          }}
        />,
        {
          label: 'FAQs',
          leftIcon: <SpriteIcon icon="question_mark" size={14} />,
          size: 'medium',
          isLink: true,
          href: '/pro/all',
        },
        <EntityEditButton className={classes.editListItem} trackingData={trackingData} size="medium" asTextBtn />,
      );
    }

    return {
      primaryActions: !organizationPermissions.canEdit ? actions.primary : [],
      collapsedActions: actions.collapsed,
    };
  }, [entity, mainPath, organizationPermissions.canEdit, t, navigate, entityType, trackingData]);
  const showShareEntity = !showEditBtn || isDesktop;
  const hideLeftActions = (isSticky && showEditBtn) || (!showShareEntity && !primaryActions?.length);
  if (isMobile && isSticky) {
    return null;
  }

  return (
    <div
      className={classnames(classes.actions, {
        [classes.actions__emptyLeftActions]: hideLeftActions,
      })}
    >
      {(!showEditBtn || showShareEntity) && (
        <div
          className={classnames(classes.leftSide, {
            [classes.leftSide__hidden]: hideLeftActions,
          })}
        >
          {!showEditBtn &&
            primaryActions?.map((action, index) => (
              <div key={index}>
                <SecondaryButton shape="rounded" {...action}>
                  {action?.label}
                </SecondaryButton>
              </div>
            ))}
          {showShareEntity && (
            <ShareEntity
              entityType={entityType}
              entity={entity}
              showLabelText={!isMobile || !primaryActions?.length}
              className={classnames({
                [classes.leftSide__shareEntity]: !primaryActions?.length,
              })}
              trackingData={{
                ...trackingData,
                component: COMPONENTS.SHARE_CTA,
              }}
            />
          )}
        </div>
      )}

      <div className={classes.rightSide}>
        {(!isSticky || (isSticky && !showEditBtn)) && (
          <>
            <FAQ
              trackingData={{
                ...trackingData,
                component: COMPONENTS.FAQ_CTA,
              }}
            />
            {entityType === ENTITY_TYPE.ARTIST && (
              <div className={classes.rightSide__castingToolPromo}>
                <CastingToolPromo
                  entityType={entityType}
                  entity={entity}
                  asCTA
                  trackingData={{
                    ...trackingData,
                    component: COMPONENTS.VIEW_IN_CASTING_TOOL_CTA,
                  }}
                />
              </div>
            )}
            {canEdit && !isMobile && (
              <div className={classes.rightSide__shareAccess}>
                <EntityShareAccess
                  entityType={entityType}
                  entity={entity}
                  trackingData={{
                    ...trackingData,
                    component: COMPONENTS.SHARE_ACCESS_CTA,
                  }}
                />
              </div>
            )}
          </>
        )}
        {showEditBtn && (
          <div className={classes.rightSide__editBtn}>
            <EntityEditButton
              className={classes.editAction}
              size="medium"
              shape="rounded"
              trackingData={trackingData}
            />
          </div>
        )}

        {showEditBtn && isMobile && <KebabMenu options={collapsedActions} />}
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModalDrawer} allowMobileDrawer title={t(`${TP}.FN_MORE_ACTION`)}>
        <OrgHeaderActionsList
          entityType={entityType}
          entity={entity}
          onClose={handleCloseModalDrawer}
          showEditBtn={showEditBtn}
          handleCheckPermissionPopup={onEditClickHandler}
        />
      </Modal>
    </div>
  );
};

export default Actions;
