import { useMemo } from 'react';
import { useQuery } from 'utils/react-query';

import queries from 'containers/Accounts/queries';
import agencyQueries from 'containers/Managers/queries';
import { SUBSCRIPTIONS_STATUSES } from 'containers/UserContainers/AccountSettingsContainer/constants';
import usePermissions from 'utils/permissions';
import {
  ENTITY_TYPE,
  PROFILE_TYPES,
  VALIDATION_STATUS,
  PROFILE_TYPE_IDS,
  VALID_AGENCY_STATUSES,
} from 'constants/index';
import useGetTargetProfile from './useGetTargetProfile';
import { useAccessPendingProfileIds, useProfileAccesses } from './useAuthenticatedUser';

const useEntityEditPermission = ({ entityType, entity }) => {
  const permissions = usePermissions(entity?.id, entityType);
  const {
    userData,
    activeProfile,
    isTargetMyProfile,
    isAdmin,
    artistPermissions,
    organizationPermissions,
    userProfiles,
  } = permissions;
  const { data: profileAccesses } = useProfileAccesses();

  const { profileData } = useGetTargetProfile(entity?.id);
  const isArtistProfile = entityType === ENTITY_TYPE.ARTIST;

  const profileIdsPendingAccess = useAccessPendingProfileIds();
  const hasTargetProfileAccess = !!profileAccesses.data?.find(
    profile => profile?.profile?.id === entity?.id && !profile.isOwner,
  );
  const agencyId = useMemo(() => {
    const agencyProfiles = userProfiles?.filter(
      profile =>
        profile?.profileType?.id === PROFILE_TYPE_IDS.AGENT &&
        profile?.agencies?.some(agency => VALID_AGENCY_STATUSES.includes(agency?.status)),
    );

    return agencyProfiles?.[0]?.agencies?.find(agency => VALID_AGENCY_STATUSES.includes(agency?.status))?.id;
  }, [userProfiles]);

  const { data: artistsInRoaster } = useQuery(
    agencyQueries.getAgencyArtists({
      agencyId,
      queryConfig: {
        enabled: !!agencyId,
        select: response => response?.data?.map(artist => artist?.artist?.profile?.id),
      },
    }),
  );

  const isArtistInRoster = agencyId
    ? [PROFILE_TYPES.PRO, PROFILE_TYPES.FREEMIUM].includes(activeProfile?.subscriptionStatus) &&
      artistsInRoaster?.includes(entity?.id)
    : false;

  const hasArtistProfileAccess = useMemo(
    () =>
      isArtistProfile &&
      (isTargetMyProfile || userProfiles?.some(profile => profile?.id === entity?.id) || isArtistInRoster),
    [isArtistProfile, isTargetMyProfile, userProfiles, isArtistInRoster, entity?.id],
  );

  const isVerificationPending = isArtistProfile
    ? entity?.validationStatus?.slug === VALIDATION_STATUS.PENDING || profileIdsPendingAccess?.includes(entity?.id)
    : !!activeProfile?.pendingValidations?.length || entity?.validationStatus?.slug === VALIDATION_STATUS.PENDING;

  const { data: profileSubscriptions } = useQuery(
    queries.getSubscriptions({
      profileId: entity?.id,
      queryConfig: { enabled: hasArtistProfileAccess && !!userData?.id },
    }),
  );

  const { data: registrations } = useQuery(
    queries.getRegistrations({ userId: userData?.id, queryConfig: { enabled: !!userData?.id } }),
  );

  const activeRegistration = useMemo(
    () => registrations?.data?.find(reg => reg?.entities?.profile?.id === profileData?.id),
    [profileData?.id, registrations?.data],
  );

  const getSubscriptionStatus = (sub, isSharedProfile, userDataId) => {
    const validStatuses = [
      SUBSCRIPTIONS_STATUSES.inTrial,
      SUBSCRIPTIONS_STATUSES.active,
      SUBSCRIPTIONS_STATUSES.cancelled,
      SUBSCRIPTIONS_STATUSES.nonRenewing,
    ];

    if (isSharedProfile) {
      const senderUserId = profileAccesses?.data?.[0]?.senderUser?.id;
      return sub?.user?.id === senderUserId && validStatuses?.includes(sub?.status);
    }

    return sub?.user?.id === userDataId && validStatuses?.includes(sub?.status);
  };

  const profileSubscription = useMemo(() => {
    const isSharedProfile = profileAccesses?.data?.[0]?.shareAccess;
    const userDataId = userData?.id;

    return profileSubscriptions?.data?.find(sub => getSubscriptionStatus(sub, isSharedProfile, userDataId));
  }, [profileAccesses?.data, userData?.id, profileSubscriptions?.data, getSubscriptionStatus]);

  const showEditBtn = organizationPermissions.canEdit || artistPermissions?.canEdit || isAdmin;

  const canEdit = organizationPermissions.canEdit || artistPermissions?.canEdit || isAdmin;
  const hasSubscription = artistPermissions?.canEdit; // NOTE: to fix with flows
  return {
    showEditBtn,
    canEdit,
    isVerificationPending,
    hasSubscription,
    hasTargetProfileAccess,
    isArtistInRoster,
    profileAccesses,
    activeRegistration,
    profileSubscription,
  };
};

export default useEntityEditPermission;
