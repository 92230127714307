/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import Drawer from '../Drawer';
import LinkButton from '../LinkButton';
import Typography from '../Typography';
import SpriteIcon from '../SpriteIcon';
import DotsMoreIcon from './DotsMoreIcon';

import classes from './KebabMenu.module.scss';

const RenderItems = ({ options }) => (
  <div className={classes.container}>
    {options?.map((item, index) =>
      item?.href || item?.onClick ? (
        <LinkButton
          key={`${item?.label}-${index}`}
          variant="text"
          styles={{ root: classes.item }}
          disableHover
          disableUnderline
          {...item}
          isLink
        >
          {item?.label}
        </LinkButton>
      ) : (
        <div key={`${index}`} className={classes.item}>
          {item}
        </div>
      ),
    )}
  </div>
);

const KebabMenu = ({ label = '', options, typographyProps }) => {
  const { isMobile } = useDeviceTypeLayouts();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, event => {
    if (!isMobile) {
      setIsOpen(prev => {
        if (prev) {
          event.stopPropagation();
          event.preventDefault();
        }
        return false;
      });
    }
  });

  if (!options?.length) {
    return null;
  }

  const onCloseHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen(false);
  };

  const onOpenHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <>
      <div ref={ref} className={classes.root} onClick={onOpenHandler}>
        <Typography className={classes.iconWithoutLabel} {...typographyProps}>
          {label}
          <DotsMoreIcon />
        </Typography>
      </div>
      {isMobile && (
        <Drawer header={<SpriteIcon icon="operabaseIcon" />} isOpen={isOpen} onClose={onCloseHandler}>
          <RenderItems options={options} />
        </Drawer>
      )}
      {!isMobile && isOpen && <RenderItems options={options} />}
    </>
  );
};

export default KebabMenu;
