import React, { useRef, useCallback, useMemo, useState } from 'react';
import { useQuery } from 'utils/react-query';
import classnames from 'classnames';
import queries from 'containers/Media/queries';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import VideoPlayer from 'components/uiLibrary/VideoPlayer';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import ToggleButton from 'components/uiLibrary/ToggleButton';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import MediaCard from 'components/Globals/MediaCard';
import Breadcrumbs from 'components/uiLibrary/Breadcrumbs';
import CastingToolPromo from 'components/Globals/CastingToolPromo';
import MapView from 'components/Globals/MapView';

import withLightbox from 'utils/hocs/withLightbox';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';
import { TP, ENTITY_TYPE } from 'constants/index';
import { getMediaSource } from 'utils/media';
import { getProductionMeta } from 'utils/productions';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import { SECTIONS, COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import CardWithProductionInfo from '../CardWithProductionInfo';

import classes from './MediaWidget.module.scss';

const mediaImageTrackingData = {
  section: SECTIONS.MEDIA_WIDGET,
  component: COMPONENTS.COVER_PHOTO,
};

const mediaPosterTrackingData = {
  section: SECTIONS.MEDIA_WIDGET,
  component: COMPONENTS.PHOTO,
};

const mediaVideoTrackingData = {
  section: SECTIONS.MEDIA_WIDGET,
  component: COMPONENTS.VIDEO,
};

const mediaBtnTrackingData = {
  ...mediaImageTrackingData,
  component: COMPONENTS.MEDIA_BUTTON,
};

const MEDIA_SECTION_TYPE = {
  POSTER: 'POSTER',
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
};

const TOGGLE_LABEL = {
  label: `${TP}.MEDIA`,
  icon: <PlayCircleOutlineIcon className={classes.mediaIcon} />,
};

const MEDIA_OPTIONS = [
  {
    type: MEDIA_SECTION_TYPE.VIDEO,
    icon: <SpriteIcon icon="play_circle" />,
    asLink: true,
    value: 'videos',
  },
  {
    type: MEDIA_SECTION_TYPE.PHOTO,
    icon: <SpriteIcon icon="image" />,
    asLink: true,
    value: 'images',
  },
];

const MediaPageBtn = ({ type, options, showDropdownAlways, isEmbedded, maxSingleMedia, btnLabel, totalMediaCount }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { isDesktop } = useDeviceTypeLayouts();
  const { linkProps } = useMemo(
    () => ({
      linkProps: options?.find(option => option?.type === type)?.linkProps,
    }),
    [options, type],
  );

  return (
    <div className={classes.btnWrap}>
      {(maxSingleMedia || showDropdownAlways) && options?.length > 1 ? (
        <ToggleButton
          title={t(TOGGLE_LABEL.label, { number: totalMediaCount })}
          options={options}
          label={t(TOGGLE_LABEL.label, { number: totalMediaCount })}
          leftIcon={TOGGLE_LABEL.icon}
          isEmbedded={isEmbedded}
          trackingData={mediaBtnTrackingData}
          {...(!isDesktop && { styles: { selectedOption: classes.actionBtn } })}
          alwaysDropdown
          enableDarkMode
        />
      ) : (
        linkProps &&
        btnLabel?.trim()?.length > 0 && (
          <LinkButton
            {...linkProps}
            external={isEmbedded}
            isLink
            disableUnderline
            leftIcon={<SpriteIcon icon={type === MEDIA_SECTION_TYPE.PHOTO ? 'image' : 'play_circle'} />}
            trackingData={{
              ...(type === MEDIA_SECTION_TYPE.PHOTO ? mediaImageTrackingData : mediaVideoTrackingData),
              subComponent: SUB_COMPONENTS.VIEW_MORE_CTA,
            }}
            styles={{
              root: classnames(classes.btnWrap__btn, {
                [classes.actionBtn]: !isDesktop,
              }),
            }}
          >
            {btnLabel}
          </LinkButton>
        )
      )}
    </div>
  );
};

const MediaImage = ({
  production,
  mediaURL,
  altText,
  titleText,
  onClick,
  renderMediaWidth,
  disabledProductionInfo,
  attribution,
  options,
  showMediaOptionsAlways,
  maxSingleMedia,
  actionLabel,
  totalMediaCount,
  showCTA,
}) => {
  const { isDesktop } = useDeviceTypeLayouts();
  const ref = useRef(null);
  const imageProps = {
    src: mediaURL,
    transformations: TRANSFORMATIONS.MEDIA_WIDGET_BANNER,
    height: 296,
    width: renderMediaWidth,
    alt: altText,
    title: titleText,
    onClick,
    lazy: false,
    fetchpriority: 'high',
    preload: true,
    disableNextImage: true,
  };

  return (
    <CardWithProductionInfo
      production={production}
      disabled={disabledProductionInfo}
      trackingData={mediaImageTrackingData}
    >
      <div ref={ref} className={classnames(classes.media, classes.typeImage)}>
        <MediaCard
          imageProps={imageProps}
          attribution={attribution}
          attributionClassName={classnames({
            [classes.mediaAttributionWithProduction]: !disabledProductionInfo && production?.id,
          })}
          imageTrackingData={mediaImageTrackingData}
        />
        {showCTA && isDesktop && (
          <MediaPageBtn
            type={MEDIA_SECTION_TYPE.PHOTO}
            options={options}
            showDropdownAlways={showMediaOptionsAlways}
            maxSingleMedia={maxSingleMedia}
            btnLabel={actionLabel}
            totalMediaCount={totalMediaCount}
          />
        )}
      </div>
    </CardWithProductionInfo>
  );
};

const MediaSection = ({
  mediaContent,
  media,
  type,
  posterTransformations,
  options,
  showMediaOptionsAlways = false,
  altText,
  titleText,
  isEmbedded,
  onClick,
  maxSingleMedia,
  showPoster,
  showAbout,
  actionLabel,
  totalMediaCount,
  renderMediaWidth,
  setIsVideoPlaying,
  isVideoPlaying,
  isPrimaryEntity,
  hasPhotoMedia,
  showCTA,
  entity,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { isMobile, isDesktop } = useDeviceTypeLayouts();

  const mediaMeta = useMemo(() => {
    const { production, title = '' } = media || {};

    return {
      title,
      ...(production && getProductionMeta(production)),
    };
  }, [media]);

  const onPlayHandler = useCallback(() => {
    setIsVideoPlaying(true);
  }, [setIsVideoPlaying]);

  const onPauseHandler = useCallback(() => {
    setIsVideoPlaying(false);
  }, [setIsVideoPlaying]);

  if (!media) {
    return null;
  }

  const mediaURL = getMediaSource(media);

  if (type === MEDIA_SECTION_TYPE.POSTER) {
    if (!showPoster) {
      return null;
    }

    return (
      <div
        className={classnames(classes.poster, {
          [classes.poster_primaryEntity]: !!isPrimaryEntity && !isDesktop,
        })}
      >
        <Image
          src={mediaURL}
          transformations={posterTransformations}
          alt={altText}
          title={titleText}
          lazy={false}
          onClick={() => onClick({ data: [media] })}
          preload
          trackingData={mediaPosterTrackingData}
          disableNextImage
          fetchPriority="high"
          attribution={{
            source_info: entity?.logo_source_info,
            credit: entity?.logo_credit,
            copyright: entity?.logo_copyright,
          }}
          {...(!isDesktop && { height: isMobile ? 115 : 194, width: isMobile ? 78 : 135 })}
        />
      </div>
    );
  }

  if (type === MEDIA_SECTION_TYPE.PHOTO) {
    if (!mediaURL) {
      return null;
    }

    return (
      <MediaImage
        production={media?.production}
        disabledProductionInfo={!showAbout || !isDesktop}
        actionLabel={actionLabel}
        mediaURL={mediaURL}
        mediaMeta={mediaMeta}
        options={options}
        showMediaOptionsAlways={showMediaOptionsAlways}
        altText={t(`${TP}.FN_SHOW_ALL_PHOTOS_OF_ENTITY`, { entityName: altText })}
        titleText={t(`${TP}.FN_SHOW_ALL_PHOTOS_OF_ENTITY`, { entityName: titleText })}
        isEmbedded={isEmbedded}
        onClick={() => onClick({ data: [media] })}
        maxSingleMedia={maxSingleMedia}
        totalMediaCount={totalMediaCount}
        renderMediaWidth={renderMediaWidth}
        showCTA={showCTA}
        attribution={{
          source: media?.source,
          credit: media?.credit,
          copyright: media?.copyright,
        }}
      />
    );
  }

  if (type === MEDIA_SECTION_TYPE.VIDEO) {
    if (!media?.url && !isDesktop && hasPhotoMedia) {
      return null;
    }

    return (
      <CardWithProductionInfo
        production={media?.production}
        disabled={!showAbout || !isDesktop}
        trackingData={mediaVideoTrackingData}
      >
        <div className={classnames(classes.media, classes.typeVideo)}>
          <VideoPlayer
            mediaContent={mediaContent}
            media={{ ...mediaMeta, ...media }}
            onPlay={onPlayHandler}
            onPause={onPauseHandler}
            height={296}
            width={renderMediaWidth}
            lazy={false}
            useIntersectionObserver={false}
            fetchpriority="high"
            preload
            hideOverlay
            showVideoInfo={!media?.production?.id}
            trackingData={mediaVideoTrackingData}
            attributionClassName={classnames({
              [classes.mediaAttributionWithProduction]: showAbout && isDesktop && media?.production?.id,
            })}
          />
          {showCTA && !isVideoPlaying && isDesktop && (
            <MediaPageBtn
              btnLabel={actionLabel}
              type={type}
              options={options}
              showDropdownAlways={showMediaOptionsAlways}
              isEmbedded={isEmbedded}
              maxSingleMedia={maxSingleMedia}
              totalMediaCount={totalMediaCount}
            />
          )}
        </div>
      </CardWithProductionInfo>
    );
  }

  return null;
};

const BreadcrumbComponent = ({ breadcrumbs, entityType, entity }) => (
  <div className={classes.breadcrumbs}>
    <Breadcrumbs data={breadcrumbs} maxItems={1} overlay />
    {entityType === ENTITY_TYPE.ARTIST && (
      <CastingToolPromo
        entityType={entityType}
        entity={entity}
        asCTA
        trackingData={{ ...mediaImageTrackingData, component: COMPONENTS.VIEW_IN_CASTING_TOOL_CTA }}
      />
    )}
  </div>
);

const MediaWidget = ({ entityType, entity, isEmbedded, showLightbox, breadcrumbs }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate } = usePageContext();
  const { isDesktop } = useDeviceTypeLayouts();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const isPrimaryEntity = [ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(entityType);
  const { data, isLoading: isMediaLoading } = useQuery(
    queries.getMediaSuggestions({ entityType, entityId: entity?.id }),
  );

  const media = data?.data;
  const {
    poster,
    video,
    photo,
    hasPoster,
    hasVideo,
    hasPhoto,
    hasMap,
    shouldShowPhoto,
    showPoster: shouldShowPoster,
  } = useMemo(() => {
    let posterMedia = null;
    let posterTransformations = null;
    let mapLocation = null;

    const photoMedia = media?.find(content => content?.mediaType === 'image');

    const firstPlayableVideo = media?.find(({ playable }) => playable);

    switch (entityType) {
      case ENTITY_TYPE.ARTIST: {
        posterMedia = { id: entity?.id, file: { urls: entity?.image } };
        posterTransformations = TRANSFORMATIONS.PROFILE_IMAGE;

        break;
      }
      case ENTITY_TYPE.ORGANIZATION: {
        mapLocation = !!(entity?.lat && entity?.lon);
        posterMedia = { id: entity?.id, file: { urls: entity?.logo } };
        posterTransformations = TRANSFORMATIONS.ORG_LOGO;

        break;
      }
      case ENTITY_TYPE.PRODUCTION: {
        posterMedia = { id: entity?.id, file: entity?.poster?.file };
        posterTransformations = TRANSFORMATIONS.POSTER;
        break;
      }
      default: {
        break;
      }
    }

    const showPaidSection = entityType === ENTITY_TYPE.ARTIST ? entity?.stats?.isPro : true;
    const digitalVideosStats = entity?.stats?.media?.videos?.digital;
    const showVideosTabBtn = showPaidSection ? entity?.stats?.media?.videos?.exists : digitalVideosStats?.exists;
    const showPhotosTabBtn = showPaidSection && entity?.stats?.media?.images?.exists;

    let videos = entity?.stats?.media?.videos?.total || 0;
    const images = entity?.stats?.media?.images?.total || 0;
    let total = entity?.stats?.media?.total || 0;

    if (!showPaidSection) {
      videos = digitalVideosStats?.total || 0;
      total = digitalVideosStats?.total || 0;
    }

    const imageCTALabel = !isDesktop
      ? t(`${TP}.FN_TAB_PHOTOS`)
      : t(`${TP}.FN_SHOW_ALL_PHOTOS`, {
          ...(images > 1 && { number: `(${images})` }),
        });
    const videoCTALabel = !isDesktop
      ? t(`${TP}.OPERA_ONLINE`)
      : t(`${TP}.FN_SHOW_ALL_VIDEOS`, {
          ...(videos > 1 && { number: `(${videos})` }),
        });

    const filteredMediaOptions = MEDIA_OPTIONS.reduce((acc, item) => {
      if (item.type === MEDIA_SECTION_TYPE.PHOTO && showPhotosTabBtn && !!photoMedia) {
        acc.push({ ...item, label: images > 1 ? imageCTALabel : '' });
      }

      if (item.type === MEDIA_SECTION_TYPE.VIDEO && showVideosTabBtn && !!firstPlayableVideo) {
        acc.push({ ...item, label: videos > 1 ? videoCTALabel : '' });
      }

      return acc;
    }, []);

    const mediaOptionsList = filteredMediaOptions?.map(item => ({
      ...item,
      linkProps: navigate.getLinkProps({ entityType, entity, path: item.value }),
    }));

    const showMediaOptionsAlways = !(!!firstPlayableVideo && !!photoMedia);
    const hasPosterMedia = !!posterMedia?.file?.urls?.medium || mapLocation;
    const hasPhotoMedia = !!photoMedia;
    const hasVideoMedia = !!firstPlayableVideo;
    const showPhotoBlock = showPaidSection && hasPhotoMedia && !(!isDesktop && hasVideoMedia);

    let renderMediaWidth = hasPosterMedia ? 1076 : 1280;

    if (hasVideoMedia && showPhotoBlock) {
      renderMediaWidth = hasPosterMedia ? 536 : 638;
    }

    const posterMediaUrl = getMediaSource(posterMedia);
    let showPoster = !!posterMediaUrl;

    let showAbout = media?.some(prod => prod?.production?.id);

    if (!isDesktop) {
      if (entityType === ENTITY_TYPE.ORGANIZATION) {
        showPoster = !!posterMediaUrl;
      }
      if (entityType === ENTITY_TYPE.PRODUCTION) {
        showAbout = !showPoster;
      }
    }

    return {
      poster: (
        <MediaSection
          type={MEDIA_SECTION_TYPE.POSTER}
          showAbout={showAbout}
          showPoster={showPoster}
          media={posterMedia}
          posterTransformations={posterTransformations}
          altText={entity?.name}
          titleText={entity?.name}
          onClick={({ ...args }) => showLightbox({ ...args, trackingData: mediaPosterTrackingData })}
          mediaContent={entity?.metaInfo}
          maxSingleMedia={!isDesktop}
          isPrimaryEntity={isPrimaryEntity}
          hasVideo={hasVideoMedia}
          entity={entity}
        />
      ),
      video: (
        <MediaSection
          showAbout={showAbout}
          showPoster={showPoster}
          type={MEDIA_SECTION_TYPE.VIDEO}
          media={firstPlayableVideo}
          options={mediaOptionsList}
          showMediaOptionsAlways={showMediaOptionsAlways}
          isEmbedded={isEmbedded}
          mediaContent={entity?.metaInfo}
          maxSingleMedia={!isDesktop}
          actionLabel={videos > 1 || digitalVideosStats?.exists ? videoCTALabel : ''}
          totalMediaCount={total}
          renderMediaWidth={renderMediaWidth}
          setIsVideoPlaying={setIsVideoPlaying}
          isVideoPlaying={isVideoPlaying}
          isPrimaryEntity={isPrimaryEntity}
          hasPhotoMedia={hasPhotoMedia}
          showCTA={showVideosTabBtn}
          entity={entity}
        />
      ),
      photo: (
        <MediaSection
          showAbout={showAbout}
          showPoster={showPoster}
          type={MEDIA_SECTION_TYPE.PHOTO}
          media={photoMedia}
          options={mediaOptionsList}
          showMediaOptionsAlways={showMediaOptionsAlways}
          isEmbedded={isEmbedded}
          altText={entity?.name}
          titleText={entity?.name}
          onClick={({ ...args }) => showLightbox({ ...args, trackingData: mediaImageTrackingData })}
          maxSingleMedia={!isDesktop}
          mediaContent={entity?.metaInfo}
          actionLabel={images > 1 ? imageCTALabel : ''}
          totalMediaCount={total}
          renderMediaWidth={renderMediaWidth}
          isPrimaryEntity={isPrimaryEntity}
          showCTA={showPhotosTabBtn}
          entity={entity}
        />
      ),
      hasMap: mapLocation,
      hasPoster: !!posterMedia?.file?.urls?.medium || mapLocation,
      hasVideo: hasVideoMedia,
      hasPhoto: hasPhotoMedia,
      shouldShowPhoto: showPhotoBlock,
      showPoster,
    };
  }, [media, entityType, entity, isDesktop, t, showLightbox, isPrimaryEntity, isEmbedded, isVideoPlaying, navigate]);

  const className = useMemo(() => {
    if (hasPoster && hasVideo && hasPhoto && shouldShowPhoto) {
      return classes.allMediaWrapper;
    }

    if (hasPoster && (hasVideo || (hasPhoto && shouldShowPhoto))) {
      return classes.posterMediaWrapper;
    }

    if (hasVideo && hasPhoto && shouldShowPhoto) {
      return classes.landscapeMediaDoubleWrapper;
    }

    if (hasVideo || (hasPhoto && shouldShowPhoto)) {
      return classes.landscapeMediaWrapper;
    }

    return null;
  }, [hasPoster, hasVideo, hasPhoto, shouldShowPhoto]);

  if (isMediaLoading) {
    return <Skeleton variant="rectangular" height={296} />;
  }

  if (!className) {
    return null;
  }

  const subText =
    entityType === ENTITY_TYPE.ORGANIZATION
      ? `${entity?.metaInfo?.subTitle} | ${entity?.organizationType?.name}`
      : entity?.metaInfo?.subTitle;

  const map = hasMap && (
    <div className={classes.poster}>
      <MapView
        latitude={entity?.lat}
        longitude={entity?.lon}
        placeId={entity?.placeId}
        label={entity?.name}
        width={198}
        height={304}
      />
    </div>
  );

  return (
    <>
      <div className={classes.wrapper}>
        {breadcrumbs && !isDesktop && (
          <BreadcrumbComponent breadcrumbs={breadcrumbs} entity={entity} entityType={entityType} isMediaWidgetExists />
        )}
        <div
          className={classnames(
            classes.mediaWidget,
            { [classes.productionMediaWidget]: entityType === ENTITY_TYPE.PRODUCTION },
            className,
          )}
        >
          {!hasMap && poster}
          {map}
          {video}
          {shouldShowPhoto && photo}
        </div>
        {[ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(entityType) && !isDesktop && (
          <header
            className={classnames(classes.content, {
              [classes.content_withPoster]: !!shouldShowPoster,
            })}
          >
            <Typography variant="h1" weight="bold" size="20" color="none" className={classes.mediaWidget__title}>
              {entity?.name}
            </Typography>
            {subText && (
              <Typography variant="p" size="12" color="none" className={classes.mediaWidget__headline} truncate>
                {subText}
              </Typography>
            )}
          </header>
        )}
      </div>
    </>
  );
};

export default withLightbox(MediaWidget);
