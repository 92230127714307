import React, { useMemo, useCallback } from 'react';

import AutoCompleteDropdown from 'components/uiLibrary/FormInputs/BaseComponents/AutoCompleteDropdown';
import useTracking from 'components/Globals/Analytics';

import queries from 'containers/Artist/queries';

import { useAppliedProfessionFilter } from 'utils/globals/ssrQueries/hooks';

import { TP } from 'constants/index';
import { PARENT_PROFESSION_SLUG } from 'constants/consts';

import { useTranslation } from 'src/i18n';

export const PROFESSION_TYPES = {
  VOICES: 'voiceTypes',
  INSTRUMENTALISTS: 'instrumentTypes',
  PROFESSIONS: 'professions',
};

const PROFESSION_TYPES_COUNT = Object.keys(PROFESSION_TYPES).length;

const LABELS = {
  [PROFESSION_TYPES.VOICES]: `${TP}.FILTER_VOICETYPES`,
  [PROFESSION_TYPES.INSTRUMENTALISTS]: `${TP}.FN_INDUSTRY_PRO_INSTRUMENTALISTS_LABEL`,
  [PROFESSION_TYPES.PROFESSIONS]: `${TP}.FILTER_PROFESSIONS`,
};

const getProfessionType = profession => {
  if (profession?.parentProfession?.id) {
    if (profession?.parentProfession?.slug === PARENT_PROFESSION_SLUG.SINGER) {
      return PROFESSION_TYPES.VOICES;
    }

    if (profession?.parentProfession?.slug === PARENT_PROFESSION_SLUG.INSTRUMENTALIST) {
      return PROFESSION_TYPES.INSTRUMENTALISTS;
    }
  }

  return PROFESSION_TYPES.PROFESSIONS;
};

const DEFAULT_TYPES = [PROFESSION_TYPES.VOICES, PROFESSION_TYPES.PROFESSIONS, PROFESSION_TYPES.INSTRUMENTALISTS];

const ArtistProfessionDropdown = ({
  types = DEFAULT_TYPES,
  className,
  value,
  onChange,
  fullWidth,
  disablePortal,
  trackingData,
}) => {
  const track = useTracking();

  const { t } = useTranslation('NS_APP_GLOBALS');

  const professionsQuery = useCallback(
    props =>
      queries.getArtistProfessions({
        ...props,
        filters: {
          ...props.filters,
          is_searchable: true,
        },
        queryConfig: {
          ...props.queryConfig,
          select: response =>
            types.reduce((acc, type) => {
              acc.push(...(response?.data?.[type] || []));
              return acc;
            }, []),
        },
      }),
    [types],
  );

  const label = useMemo(() => {
    if (types.length < PROFESSION_TYPES_COUNT) {
      return types.map(type => t(LABELS[type])).join(' / ');
    }

    return t(LABELS[PROFESSION_TYPES.PROFESSIONS]);
  }, [t, types]);

  const selectedProfession = useAppliedProfessionFilter(value);
  const profession = useMemo(() => {
    if (types.length < PROFESSION_TYPES_COUNT) {
      const professionType = getProfessionType(selectedProfession);

      if (types.includes(professionType)) {
        return selectedProfession;
      }

      return null;
    }

    return selectedProfession;
  }, [types, selectedProfession]);

  const onChangeHandler = (e, newSelectedProfession) => {
    if (onChange) {
      onChange(e, newSelectedProfession);
    }
    track.click({
      ...trackingData,
      meta: {
        id: newSelectedProfession?.slug,
      },
    });
  };

  return (
    <AutoCompleteDropdown
      className={className}
      label={label}
      value={profession}
      onChange={onChangeHandler}
      searchQuery={professionsQuery}
      queryKey="aggregation_query"
      fullWidth={fullWidth}
      disablePortal={disablePortal}
      prefetchSearchQuery
    />
  );
};

export default ArtistProfessionDropdown;
